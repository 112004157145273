import axios from 'axios';
import {obj2Params} from '@/utils/utils.js';
import {Toast, Notify} from 'vant';

// 开发环境===============================================
// const dev = 'https://api.xujixing.vip';
// const dev = 'http://127.0.0.1:19100';
const dev = 'https://szcw.szcreativeweek.com';

// 生产环境===============================================
// const prod = 'https://api.xujixing.vip';
const prod = 'https://szcw.szcreativeweek.com';


const timeoutTime = 10000; // 接口请求超时时间设置(毫秒ms)

const service = axios.create({
	baseURL: process.env.NODE_ENV === 'production' ? prod : dev, // 接口请求默认根路径
	// baseURL: process.env.NODE_ENV === 'dev' ? prod : dev, // 接口请求默认根路径
	method: 'post',
	timeout: timeoutTime,
	headers: {
		'content-type': 'application/json;charset=UTF-8', // JSON
		// 'content-type': 'application/x-www-form-urlencoded;charset=UTF-8', // formData
		// 'content-type': 'multipart/form-data', // formData - 文件流上传
	},
})

// request 请求拦截器
service.interceptors.request.use(config => {
	// if (!config.headers.hasOwnProperty('content-type')) {}
  if (config.params) {
    config.urls = config.urls + '?' + obj2Params(config.params);
  }
	return config;
}, error => {
	console.log('requestError: ', error);
	return Promise.reject(error);
})

// response 响应拦截器
service.interceptors.response.use(response => {
	let {status, config, data: res} = response;
	if (status === 200) {
		if (res.code == 200 || res.code == 1 || res.resultStatus) {
			return res
		} else {
      if (config.isShowNotify) {
        Notify({ message: res.message, duration: 3000 });
      } else {
        Toast(res.message);
      }
      return Promise.reject(res);
		}
		
	} else {
		return Promise.reject(new Error());
	}
}, error => {
	console.log('error: ', error);
	console.log('errorResponse: ', error.response); // {data: {…}, status: 500, statusText: 'Internal Server Error', headers: {…}, config: {…}, …}
  
  // if (error.message === `timeout of ${timeoutTime}ms exceeded`) {
  //   Toast('网络请求超时');
  // } 
  
  // if (error.message === 'Network Error') {
  //   Toast('网络异常');
  // } 
  
  // if (error.response && error.response.status) { // 如: Internal Server Error
  // }
    

	// return false;
	return Promise.reject(error);
})

export {
	service, // axios实例
};
