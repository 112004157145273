<template>
  <div>
    <!-- pc端 -->
    <div class="pc">
      <div class="head-bar flex justify-between align-center">
        <div class="lang-box flex">
          <!-- 
          <a class="lang cur" href="https://www.szcreativeweek.cn">cn</a>
          <a class="lang" href="https://www.szcreativeweek.com">en</a>
          -->
        </div>
        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/logo1.png" class="logo" style="position:absolute; left: 50%;transform: translate(-50%, 0);" />
        
        <div class="logo-box flex justify-end">
          <div class=" flex justify-end">
            <a href="https://www.xiaohongshu.com/user/profile/5cd27b05000000001700a9b0?xhsshare=CopyLink&appuid=5cd27b05000000001700a9b0&apptime=1698203565"  title="小红书" target="_blank" rel="nofollow noopener noreferrer" class="f-logo">
              <img src="../../assets/img/xhs.jpg" alt="小红书" class="block fill" />
            </a>
            <a href="https://instagram.com/szdesigndome" title="ins" target="_blank" rel="nofollow noopener noreferrer" class="f-logo">
              <img src="../../assets/img/instagram.jpg" alt="ins" class="block fill" />
            </a>
            <a title="视频号" target="_blank" class="f-logo relative shipinhao-icon">
              <img src="../../assets/img/wechat-video.jpg" alt="视频号" class="block fill" />
              <div class="absolute shipinhao-qrcode">
                <img src="../../assets/img/shipinhao.jpg" class="block fill" />
              </div>
            </a>
            <a title="微信" class="f-logo" href="/contact">
              <img src="../../assets/img/wechat.png" alt="微信" class="block fill" />
            </a>
          </div>
          <div class="lang-box flex" style="margin-left: 40px;">
            <a class="lang cur" href="https://www.szcreativeweek.cn">CN</a>
            <a class="lang" href="https://www.szcreativeweek.com">EN</a>
          </div>
        </div>
      </div>
      
      <div class="date text-center text-bold">2025年 3月16日 - 19日 &nbsp;&nbsp;&nbsp; 深圳国际会展中心</div>
      
      <div class="menu-bar ">
        <div class="menu-bar-content relative">
          <div class="menu-container relative">
            <div class="main-menu flex justify-end text-center" @click="changeMenu($event)">
              <!-- <div :class="['menu-item', currentMenu === 'home' ? 'active' : '']" data-key="home" @click="navigateTo('/home')"> -->
              <a :class="['menu-item', currentMenu === 'home' ? 'active' : '']" data-key="home" href="/">
                <span>首页</span>
              </a>
              <div :class="['menu-item', currentMenu === 'design' ? 'active' : '']" data-key="design">
                <span>关于展会</span>
                <img src="../../assets/img/ri_arrow-drop-down-line.png" alt="" class="down-icon">
              </div>
              <div :class="['menu-item', currentMenu === 'exh' ? 'active' : '']" data-key="exh">
                <span>展商入口</span>
                <img src="../../assets/img/ri_arrow-drop-down-line.png" alt="" class="down-icon">
              </div>
              <div :class="['menu-item', currentMenu === 'global' ? 'active' : '']" data-key="global">
                <span>观众入口</span>
                <img src="../../assets/img/ri_arrow-drop-down-line.png" alt="" class="down-icon">
              </div>
              <div :class="['menu-item', currentMenu === 'service' ? 'active' : '']" data-key="service">
                <span>服务</span>
                <img src="../../assets/img/ri_arrow-drop-down-line.png" alt="" class="down-icon">
              </div>
            </div>
            <div class="sub-menu-box absolute flex justify-end text-center bg-white">
              <div class="sub-menu">
                <a class="sub-menu-item" href="/intro">展会介绍</a>
                <!-- <a class="sub-menu-item" href="/theme">展会特色板块</a> -->
                <a class="sub-menu-item" href="/smart">深圳智博会</a>
                <a class="sub-menu-item" href="/bbs">高峰论坛</a>
                <a class="sub-menu-item" href="/history">往届概述</a>
                <!-- <a class="sub-menu-item" href="/competition">设计大赛</a> -->
              </div>
              <div class="sub-menu">
                <a href="https://exhibitor.szcreativeweek.com/admin/join" class="sub-menu-item" target="_blank" rel="noopener noreferrer">我要参展</a>
                <a class="sub-menu-item" href="/exhibitor">展商名录</a>
                <a class="sub-menu-item" href="/news">品牌资讯</a>
                <a class="sub-menu-item" href="/property">知产保护</a>
                <a class="sub-menu-item" href="/service">展商服务</a>
              </div>
              <div class="sub-menu">
                <a class="sub-menu-item" href="/register">预先登记</a>
                <a class="sub-menu-item" href="/expo">展会资讯</a>
                <a class="sub-menu-item" href="/picture">视频&图片</a>
                <!-- <a class="sub-menu-item" href="/guide">展会导览图</a> -->
                <a class="sub-menu-item" href="/traffic">交通商旅</a>
              </div>
              <div class="sub-menu">
                <a class="sub-menu-item" href="/contact">联系我们</a>
                <a class="sub-menu-item" href="/media">媒体中心</a>
              </div>
            </div>
          </div>
          
          <div class="search-bar">
            <input type="search" v-model.trim="keywords" placeholder="请输入搜索内容" class="search-input" @keyup.enter="toSearch" />
            <!-- <img src="../../assets/img/search.png" alt="" class="search-icon" @click="toSearch"> -->
            <a :href="'/search?keywords=' + encodeURIComponent(keywords)">
              <img src="../../assets/img/search.png" alt="" class="search-icon">
            </a>
          </div>
          
        </div>
      </div>
      
    </div>
    
    <!-- 移动端 -->
    <div class="phone">
      <div class="head-bar-phone flex justify-between">
        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/logo1.png" class="logo" />
        <div class="flex justify-end">
          <div class="lang-box flex">
            <a class="lang cur" href="https://www.szcreativeweek.cn">CN</a>
            <a class="lang" href="https://www.szcreativeweek.com">EN</a>
          </div>
          <div class="menu-icon-box flex justify-end" @click="isShowMenu = true">
            <img src="../../assets/img/menu.png" alt="" class="menu-icon">
          </div>
        </div>
      </div>
 
      <div :class="['navigation-mobile', isShowMenu ? 'is-visible' : '', ]">
        <span class="el-icon-close close-icon" @click="isShowMenu = false"></span>
        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/logo1.png" class="logo" />
        <div class="menu-container">
          <a class="menu-item" href="/">首页</a>
          <el-collapse v-model="currentMenu" accordion @change="handleChange">
            <el-collapse-item title="关于展会" name="design">
              <a class="block" href="/intro">展会介绍</a>
              <a class="block" href="/theme">展会特色板块</a>
              <a class="block" href="/smart">深圳智博会</a>
              <a class="block" href="/bbs">高峰论坛</a>
              <a class="block" href="/history">往届概述</a>
              <!-- <a class="block" href="/competition">设计大赛</a> -->
            </el-collapse-item>
            <el-collapse-item title="展商入口" name="exh">
              <a class="block" href="https://exhibitor.szcreativeweek.com/admin/join" target="_blank" rel="noopener noreferrer">我要参展</a>
              <a class="block" href="/exhibitor">展商名录</a>
              <a class="block" href="/news">品牌资讯</a>
              <a class="block" href="/property">知产保护</a>
              <a class="block" href="/service">展商服务</a>
            </el-collapse-item>
            <el-collapse-item title="观众入口" name="global">
              <a class="block" href="/register">预先登记</a>
              <a class="block" href="/expo">展会资讯</a>
              <a class="block" href="/picture">视频&图片</a>
              <a class="block" href="/guide">展会导览图</a>
              <a class="block" href="/traffic">交通商旅</a>
            </el-collapse-item>
            <el-collapse-item title="服务" name="service">
              <a class="block" href="/contact">联系我们</a>
              <a class="block" href="/media">媒体中心</a>
            </el-collapse-item>
          </el-collapse>
        </div>
        
        <div class="logo-box flex">
          <a href="https://www.xiaohongshu.com/user/profile/5cd27b05000000001700a9b0?xhsshare=CopyLink&appuid=5cd27b05000000001700a9b0&apptime=1698203565"  title="小红书" target="_blank" rel="noopener noreferrer" class="f-logo">
            <img src="../../assets/img/xhs.jpg" alt="小红书" class="block fill" />
          </a>
          <a href="https://instagram.com/szdesigndome" title="ins" target="_blank" rel="noopener noreferrer" class="f-logo">
            <img src="../../assets/img/instagram.jpg" alt="ins" class="block fill" />
          </a>
          <a title="视频号" target="_blank" class="f-logo relative shipinhao-icon" style="display: none;" >
            <img src="../../assets/img/wechat-video.jpg" alt="视频号" class="block fill" />
            <div class="absolute shipinhao-qrcode">
              <img src="../../assets/img/shipinhao.jpg" class="block fill" />
            </div>
          </a>
          <a title="微信" class="f-logo" href="/contact">
            <img src="../../assets/img/wechat.png" alt="微信" class="block fill" />
          </a>
        </div>
        
        <div class="btn-box">
          <a href="https://exhibitor.szcreativeweek.com/admin/join" target="_blank" rel="noopener noreferrer" class="btn text-light">我要参展</a>
          <a class="btn text-light reg-btn" href="/register">预先登记</a>
        </div>
        
      </div>
    </div>
    

  </div>
</template>

<script>
  export default {
    props: {
      active: {
        type: String,
        default: 'home'
      },
      subActive: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        currentMenu: this.active,
        isShowMenu: false,
        keywords: '',
      }
    },
    created() {
      
    },
    methods: {
      changeMenu(e) {
        // console.log(e.target.dataset.key);
        // this.currentMenu = e.target.dataset.key
      },
      handleChange(val) {
        console.log(val);
      },
      navigateTo(path) {
        // 目标页为当前页时无导航跳转
        if (this.subActive && path.endsWith(this.subActive)) {
          return
        } else {
          this.$router.push(path)
        }
      },
      toContact() {
        this.$router.push('/contact')
      },
      toSearch() {
        if (this.keywords) {
          this.$router.push({
            path: '/search',
            query: {
              keywords: encodeURIComponent(this.keywords)
            }
          })
        }
      },
    },
  }
</script>

<style scoped lang="less">
  .block {
    display: block;
  }
  
  // 移动端
  @media screen and (max-width: 1023px) {
    .pc {
      display: none;
    }
    .phone {
      display: block;
    }
    
    .head-bar-phone {
      padding: 10px;
    
      .logo {
        width: 160px;
        height: auto;
        margin: 5px;
      }
      
      .menu-icon-box {
        width: 32px;
        height: 32px;
      }
      .menu-icon {
        width: 28px;
        height: 28px;
        padding: 4px;
      }
      
      .lang-box {
        width: 80px;
        height: 24px;
        margin: 10px 10px 0 0;
        
        .lang {
          width: auto;
          height: 14px;
          line-height: 14px;
          font-size: 14px;
          cursor: pointer;
          color: #C4C4C4;
          border-right: 2px solid #777;
          padding: 0 10px;
          font-family: 'Poppins-Bold';
          
          &:last-child {
            border-right: none;
          }
        }
        
        .cur {
          color: #222222;
        }
      }
      
    }
    
    .navigation-mobile {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3000000000;
      padding: 25px;
      overflow: auto;
      opacity: 0;
      visibility: hidden;
      transform: scale(1.1);
      transition: 0.3s ease-in;
      // background: rgba(0, 0, 0, 0.8);
      background: rgba(255, 255, 255, 0.99);
      box-sizing: border-box;
    }
    .navigation-mobile.is-visible {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
    
    .navigation-mobile {
      
      .logo {
        display: block;
        margin: 0 auto 20px;
        width: 160px;
        height: auto;
      }
      
      /deep/ .el-collapse {
        .el-collapse-item {
          .el-collapse-item__header {
            background-color: transparent;
            color: #060211;
            font-size: 14px;
          }
          
          .el-collapse-item__wrap {
            
            .el-collapse-item__content {
              padding: 10px;
              font-size: 14px;
              color: #060211;
              line-height: 30px;
              
              a {
                color: #060211;
              }
            }
          }
        }
      }
      
      .close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 901;
        font-size: 30px;
        color: #060211;
      }
      
      .menu-container {
          
        .menu-item {
          display: block;
          width: 325px;
          color: #060211;
          font-size: 15px;
          line-height: 49px;
          cursor: pointer;
        }
        
      }
      
      .tips {
        margin: 30px auto;
        color: #060211;
        font-size: 16px;
      }
      .logo-box {
        margin-top: 50px;
        
        .f-logo {
          display: block;
          width: 24px;
          height: auto;
          margin: 0 15px 0 0;
          background-color: #fff;
          border-radius: 10px;
          padding: 4px;
        }
        .shipinhao-icon {
          
          .shipinhao-qrcode {
            display: none;
            top: 7px;
            right: -10px;
            z-index: 20;
            width: 50px;
            height: auto;
            
            &:hover {
              display: block;
            }
          }
          
          &:hover {
            .shipinhao-qrcode {
              display: block;
            }
          }
        }
      }
      
      .btn-box {
        padding: 20px 0;
        
        .btn {
          display: block;
          width: 100%;
          line-height: 40px;
          border: none;
          border-radius: 4px;
          background-color: #E4007F;
          font-size: 14px;
          text-align: center;
          // color: #060211;
          color: #fff;
          margin-bottom: 10px;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
        }
        .reg-btn {
          background-color: #00ABA5;
          color: #fff;
        }
        
      }
      
    }
    

  }
  
  // PC端
  @media screen and (min-width: 1024px) {
    
    .pc {
      display: block;
    }
    .phone {
      display: none;
    }
    
    .head-bar {
      position: relative;
      width: 100%;
      padding: 3px 5px;
      line-height: 13.2px;
      font-size: 3.2px;
      border-bottom: 0.1px solid #eee;
      
      .lang-box {
        width: 24px;
        
        .lang {
          display: flex;
          align-items: center;
          justify-content: center;
          // width: 9px;
          height: 4.5px;
          font-size: 4.5px;
          cursor: pointer;
          color: #C4C4C4;
          border-right: 0.5px solid #666;
          padding: 0 3px 0.5px;
          font-family: 'Poppins-bold';
          
          &:last-child {
            border-right: none;
          }
        }
        
        .cur {
          color: #222222;
        }
      }
      
      .logo {
        display: block;
        width: 60px;
        height: auto;
      }
      
      .logo-box {
        display: flex;
        align-items: center;
        height: 13.2px;
        
        .f-logo {
          display: block;
          width: 5px;
          height: auto;
          border-radius: 1.7px;
          margin: 0 2.4px;
        }
        .shipinhao-icon {
          
          .shipinhao-qrcode {
            display: none;
            top: 7px;
            right: -10px;
            z-index: 20;
            width: 50px;
            height: auto;
            
            &:hover {
              display: block;
            }
          }
          
          &:hover {
            .shipinhao-qrcode {
              display: block;
            }
          }
        }
      }
      
    }
    
    .date {
      font-size: 5px;
      padding: 3.5px 0;
      border-bottom: 0.1px solid #eee;
    }
    
    .menu-bar {
      
      .menu-bar-content {
        border-bottom: 0.1px solid #eee;
      }
      .menu-container {
        // width: 750Px;
        width: 200px;
        margin: 0 auto;
        
        .main-menu {
          width: 100%;
          
          .menu-item {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 35px;
            height: 8px;
            font-size: 3px;
            line-height: 8px;
            font-weight: bold;
            border-radius: 4Px;
            cursor: pointer;
            margin: 2px 2.5px;
            color: #060211;
            font-family: 'SourceHanSansCN-Bold';
            
            .down-icon {
              width: 6px;
              height: auto;
            }
            
            &.active {
              // background-color: #060211;
              // color: #fff;
              background-color: #F8F8F8;
              color: #060211;
            }
            
            &:hover {
              background-color: #F8F8F8;
              color: #060211;
            }
          }
          
        }
        
        .sub-menu-box {
          display: none;
          width: 100%;
          margin: 0 auto;
          box-shadow: 0 3Px  6Px rgba(0, 0, 0, .2);
          border-radius: 0 0 4Px 4Px;
          z-index: 10;
          
          .sub-menu {
            // width: 150Px;
            width: 35px;
            // padding: 5Px 0;
            padding: 1.5px 0 5px;
            margin: 0 2.5px;
            
            .sub-menu-item {
              display: block;
              width: 100%;
              font-size: 2.8px;
              line-height: 3.6em;
              text-align: left;
              cursor: pointer;
              color: #060211;
              padding-left: 9px;
              font-family: 'SourceHanSansCN-Light';
              
              &:hover {
                font-family: 'SourceHanSansCN-Bold';
                color: #060211;
                border-radius: 3Px;
                
                a {
                  color: #fff;
                }
              }
              
              a {
                color: #060211;
              }
              
            }
          
          }
          
        }
        
        &:hover {
          .sub-menu-box {
            display: flex;
          }
        }
      }
      
      .search-bar {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 10px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 5px;
        
        .search-input {
          width: 0;
          height: 8px;
          margin: 2px;
          padding: 0 2px;
          border-radius: 1px;
          background-color: #fff;
          border: none;
          font-size: 2.8px;
          transition: 0.5s width;
          outline: none;
          
          // &::placeholder {
          //   font-size: 2.8px;
          // }
        }
        
        &:hover {
          .search-input {
            width: 55px;
            background-color: #F8F8F8;
          }
        }
        
        .search-icon {
          width: 4px;
          height: auto;
          cursor: pointer;
        }
      }
      
    }
  }
  
</style>