
import {service} from './request.js';
// import qs from 'qs';

// 上传护照或通行证
// export function uploadPassport(base64, type) {
// 	return service({
// 		url: '/h5/idCard/image/upload/base64?idCardType=' + type,
// 		data: base64,
//     isShowNotify: true,
// 	})
// }


// 根据类型获取banner
export function getHomeBanner(params) {
	return service({
		url: '/design/v1/app/common/banner',
    method: 'get',
    params
	})
}
// 获取往届概述
export function getExhibitionRecords() {
	return service({
		url: '/design/v1/app/common/exhibitionRecords',
    method: 'get',
	})
}

// 获取展馆列表
export function getPavilionList() {
	return service({
		url: '/design/v1/app/common/pavilion',
    method: 'get',
	})
}

// 根据品类获取展商列表
export function getCategoryList(params) {
	return service({
		url: '/design/v1/app/common/category',
    method: 'get',
		params
	})
}
// 根据品类、展馆获取展商列表
export function getExhibitorByCategory(params) {
	return service({
		url: '/design/v1/app/exhibitor',
    method: 'get',
		params
	})
}

// 获取展商详情
export function getExhibitorDetail(id) {
	return service({
		url: '/design/v1/app/exhibitor/' + id,
    method: 'get',
	})
}

// 获取知识保护列表
export function getOriginalList(params) {
	return service({
		url: '/design/v1/app/billBoard/original',
    method: 'get',
    params
	})
}

// 获取展会新闻资讯列表
export function getNewsList(params) {
	return service({
		url: '/design/v1/app/common/news',
    method: 'get',
    params
	})
}
// 获取展会新闻资讯详情
export function getNewsDetail(id) {
	return service({
		url: '/design/v1/app/common/news/' + id,
    method: 'get',
	})
}

// 获取品牌资讯列表（展商优推荐文章）
export function getArticleList(params) {
	return service({
		url: '/design/v1/app/article',
    method: 'get',
    params
	})
}

// 获取品牌资讯详情（展商优推荐文章）
export function getArticleDetail(id) {
	return service({
		url: `/design/v1/app/article/${id}?preview=false`,
    method: 'get',
	})
}

// 获取展会特色板块列表
export function getThemeList() {
	return service({
		url: '/design/v1/app/common/partsOfficial',
    method: 'get',
	})
}
// 获取展会特色板块列表
export function getThemeListById(partsId) {
	return service({
		url: '/design/v1/app/common/partsOfficial/'+partsId,
		method: 'get'
	})
}
// 获取活动列表
export function getActivityList(params) {
	return service({
		url: '/design/v1/app/common/partsActivity',
    method: 'get',
    params
	})
}

// 获取活动详情
export function getActivityDetail(id) {
	return service({
		url: '/design/v1/app/common/partsActivity/' + id,
    method: 'get',
	})
}

// 获取论坛分类列表
export function getCommonCategory(params) {
	return service({
		url: '/design/v1/app/common/commonCategory?categoryType=FORUM',
    method: 'get',
    params
	})
}

// 获取论坛列表
export function getForumList(params) {
	return service({
		url: '/design/v1/app/common/forumOfficial',
    method: 'get',
    params
	})
}

// 获取论坛特邀嘉宾
export function getForumGuest(params) {
	return service({
		url: '/design//v1/app/common/guest',
    method: 'get',
    params
	})
}

// 获取视频&图片列表
export function getSessionList() {
	return service({
		url: '/design/v1/app/common/session',
    method: 'get',
	})
}

// 获取视频&图片资源列表
export function getSessionDetailList(params) {
	return service({
		url: '/design/v1/app/common/sessionDetail',
    method: 'get',
    params
	})
}

// 论坛报名
export function addForumEnroll(params) {
	return service({
		url: '/design/v1/app/common/addEnroll',
    method: 'post',
    data: params
	})
}

// 获取友情链接列表
export function getExternalList() {
	return service({
		url: '/design/v1/app/common/external',
    method: 'get',
	})
}
